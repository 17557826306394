<template>
  <div class="about">
    <s-header />

    <n-bar :userType="'student'" :activeItemName="'index'" />

    <support :otherClass="'width40'" />

    <div class="pageContent">
      <div class="content">
        <div class="iconBox">
          <van-image
            width="30px"
            height="30px"
            :src="require('@/assets/images/data.png')"
          />
          <span>今日学习数据</span>
        </div>

        <div class="data">
          <van-grid :gutter="10" :column-num="4">
            <van-grid-item v-for="item in indexData.today">
              <div class="statisticsData">{{ item.text }}{{ item.unit }}</div>
              <div class="statisticsTitle">{{ item.name }}</div>
            </van-grid-item>
          </van-grid>
        </div>
      </div>

      <div class="content">
        <div class="iconBox">
          <van-image
            width="30px"
            height="30px"
            :src="require('@/assets/images/dataTotal.png')"
          />
          <span>累计学习数据</span>
        </div>

        <div class="data dataTotal">
          <van-grid :gutter="10" :column-num="4">
            <van-grid-item v-for="item in indexData.cumulative">
              <div class="statisticsData">{{ item.text }}{{ item.unit }}</div>
              <div class="statisticsTitle">{{ item.name }}</div>
            </van-grid-item>
          </van-grid>
        </div>
      </div>

      <div class="qNav">
        <qNav :list="quickNavList" :column="4"></qNav>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import sHeader from "@/components/SimpleHeader";
import nBar from "@/components/NavBar";
import support from "@/components/Support";
import qNav from "@/components/QuickNav";
import { apiGetIndexData } from "@/service/student.js";

let indexData = ref([]);

const quickNavList = [
  {
    icon: "description",
    text: "学案",
    badge: "",
    routeName: "scList",
  },
  {
    icon: require("@/assets/images/u1314.png"),
    text: "错题本",
    badge: "",
    routeName: "wrList",
  },
  {
    icon: "send-gift-o",
    text: "资料库",
    badge: "",
    routeName: "",
  },
  {
    icon: require("@/assets/images/u685.png"),
    text: "学习报告",
    badge: "",
    routeName: "",
  },
];

const getData = async () => {
  let res = await apiGetIndexData();
  indexData.value = res.data;
};
getData();
</script>

<style lang="less" scoped>
@import "@/common/style/pageContent.less";

.iconBox {
  margin-top: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  font-weight: bold;
  span {
    font-size: 12px;
    margin-left: 10px;
  }
}

.statisticsData {
  color: #18a4e0;
  font-size: 13px;
  font-weight: 700;
}

.statisticsTitle {
  color: #666;
  font-size: 10px;
}

.data {
  .info {
    width: 50px;
    height: 100px;

    background-color: rgb(102, 204, 255);
  }
}

.data {
  :deep(.van-grid-item__content) {
    background-color: #fff;
    .bs();
  }
  :deep(.van-grid-item__content) {
    box-shadow: 0.05333rem 0.05333rem 0.10667rem #eee !important;
    border: none;
    border-radius: 6px;
  }
}

@media screen and (orientation: landscape) {
}

.dataTotal {
  margin-bottom: 10px;
}

.qNav {
  padding: 0 8px;
  padding-top: 40px;
}
</style>
